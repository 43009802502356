import React, { Component } from "react";
import {
  Container,
  Row, Col,
  Button,
  Input,
  Card,
  CardBody,
  Modal,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import expertFeaturedService from "../../services/expert-featured";
import MetaTags from "react-meta-tags";
import ModalConfirm from "components/modals/modalConfirm"
import { checkPermission, customUrlParams, capitalizeFirstLetter, browserIsSafari, getVideoOptions } from 'helpers/supportFunction';
import { checkImage } from 'helpers/checkImage';
import imageExpert from "../../assets/images/image-expert.jpg";
import toast from 'helpers/toast';
import DataGrid, { Column, RowDragging, Sorting } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';

class Expert extends Component {
  constructor(props) {
    super(props)
    this.state = {
      experts: [],
      isDetail: false,
      currentExpertFeatured: null,
      isOpenConfirm: false,
      search: '',
      waiting: false,
      dxStore: [],
      onChangeOrder: false,
      open_modal_confirm: false,
      isSafari: false
    }
    this.onReorder = this.onReorder.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("expert-featured", "read")) {
      toast.warning("You do not have permission to manage banner featured", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.setState({isSafari: browserIsSafari()}, () => {
        this.loadData();
      });
    }
  }

  async loadData() {
    let url_params = ""
    let loadOptions = { take: -1, skip: 0, };
    if (this.state.search) {
      url_params += `&search=${this.state.search}`;
    }
    let params = customUrlParams(loadOptions, url_params, 'order', 'asc', false)
    var response = await expertFeaturedService.getAll(params);
    let data = [];
    if (response.success) {
      data = response.data.map((item, index) => { item.order = item.order ? item.order : index + 1; return item });
    }
    this.setState({ dxStore: data });
  }

  onReorder(e) {
    e.promise = this.processReorder(e);
  }

  async processReorder(e) {
    let dxStore = JSON.parse(JSON.stringify(this.state.dxStore));
    dxStore = this.array_move(dxStore, e.fromIndex, e.toIndex);
    dxStore = dxStore.map((item, index) => {
      item.order = index + 1;
      return item;
    })
    this.setState({
      dxStore: dxStore,
      onChangeOrder: true
    })
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  customImage = (e) => {
    let rowData = e.data;
    return (
      <div className="text-center cursor-pointer">
        <div
          onClick={() => {
            this.setState({
              isDetail: true,
              currentExpertFeatured: rowData,
            })
          }}
        >
          {
            rowData.media_type == 'video' ?
              <video
                muted
                playsInline
                preload="auto"
                style={{ backgroundColor: "#f2f5f9" }}
                className="video-featured feature-media-video"
                {...getVideoOptions(rowData.media)}
              >
                <source src={rowData.media + (this.state.isSafari ? "#t=0.1" : '')}/>
                Your browser does not support the video.
              </video>
              :
              <img
                id={`image_expert_featured_${rowData.id}`}
                src={
                  rowData.media ?
                    checkImage(rowData.media, `image_expert_featured_${rowData.id}`, imageExpert)
                    : imageExpert
                }
                className="image-expert-featured-list"
              ></img>
          }
        </div>
      </div>
    )
  }

  customName = (e) => {
    let expert = e.data;
    return (
      checkPermission("expert", "read") ?
        <>
          <Link to={`/sidekicks/${expert.expert_id}/detail`}>
            {expert.expert_name}
          </Link>
        </> :
        <span> {expert.expert_name} </span>
    )
  }

  customStatus = (e) => {
    let expert = e.data;
    return (
      <span>
        {expert.status ? capitalizeFirstLetter(expert.status) : ''}
      </span>
    )
  }

  linkEdit = (e) => {
    let expert = e.data;
    return (
      <>
        {checkPermission("expert-featured", "update") ?
          <Link to={`/sidekicks-featured/${expert.id}/edit`}
            className="btn btn-primary mx-1 btn-custom-width"
          >
            Update
          </Link>
          : ''
        }

        {checkPermission("expert-featured", "delete") ?
          <Button
            className="mx-1 btn-custom-width"
            color="danger"
            onClick={() => {
              this.setState({
                isOpenConfirm: true,
                currentExpertFeatured: expert,
              })
            }}
          >
            Delete
          </Button>
          : <></>
        }
      </>
    )
  }

  onClose = () => {
    this.setState({
      isDetail: false,
      isOpenConfirm: false,
      currentExpertFeatured: null,
    })
  }

  onActionConfirm = async () => {
    this.setState({ waiting: true });
    let response = await expertFeaturedService.deleteExpertFeatured({
      expert_featured_id: this.state.currentExpertFeatured.id,
    })
    if (response && response.success) {
      toast.success("Delete Banner Featured Success", "Delete");
      this.onClose();
      this.loadData();
    }
    this.setState({ waiting: false });
  }

  handleInputSearch = e => {
    if (this.state.onChangeOrder) {
      this.setState({ open_modal_confirm: true });
    } else {
      this.setState({ search: e }, () =>
        this.loadData()
      );
    }
  }

  saveReorder = async () => {
    this.setState({ waiting: true });
    let expert_features = [];
    this.state.dxStore.map((item, index) => {
      expert_features.push({ id: item.id, order: index + 1 });
    });
    let response = await expertFeaturedService.updateReorder({ expert_features: expert_features })
    if (response && response.success) {
      toast.success("Update Reorder Success", "Update Success");
    }
  }

  onCloseModalConfirm = () => {
    this.setState({ open_modal_confirm: false, onChangeOrder: false, waiting: false });
    this.loadData();
  }

  onActionConfirmChangeOrder = async () => {
    await this.saveReorder();
    this.onCloseModalConfirm();
  }

  onRowPrepared = (e) => {
    if (e.rowType === "data" && e.data) {
      if (e.data.showable) {
        e.rowElement.classList.add('bg-show-able');
      } else {
        e.rowElement.classList.remove('bg-show-able');
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Banner Featured | Sidekick</title>
          </MetaTags>
          <Container fluid className={`${this.state.waiting ? 'waitLoadingData' : ''}`}>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12" className="d-flex align-items-center pb-2">
                    <h4 className="mb-0 text-primary">Manage Banner Featured</h4>
                  </Col>
                  <Col xl="4" md="6" className="pt-2 pb-2">
                    <Input
                      type="search"
                      id="search"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.search}
                      onChange={e => {
                        this.handleInputSearch(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xl="8" md="6" className="text-right pt-2 pb-2">
                    {checkPermission("expert-featured", "update") ?
                      <Button
                        color="success"
                        type="button"
                        style={{ width: 120, marginRight: 5 }}
                        disabled={!this.state.onChangeOrder}
                        onClick={() => {
                          this.onActionConfirmChangeOrder()
                        }}
                      >
                        Save Reorder
                      </Button>
                      : ''}
                    {checkPermission("expert-featured", "create") ?
                      <Link to={`/sidekicks-featured/create`}
                        className="btn btn-primary btn-custom-width"
                      >
                        Create
                      </Link>
                      : ''}
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      onRowPrepared={(e) => { this.onRowPrepared(e) }}
                    >
                      <RowDragging
                        allowReordering={!this.state.search}
                        onReorder={this.onReorder}
                        showDragIcons={true}
                      />
                      <Sorting mode="none" />
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80}></Column>
                      <Column dataField="expert_name" name="expert_name" caption="Sidekick" cellRender={this.customName}></Column>
                      <Column dataField="email" name="email" caption="Sidekick Email"></Column>
                      <Column dataField="category_name" name="category_name" caption="Category"></Column>
                      <Column dataField="status" name="status" caption="Status" alignment={'center'} cellRender={this.customStatus} ></Column>
                      <Column dataField="order" name="order" caption="Order" alignment={'center'} ></Column>
                      <Column dataField="Image" name="Image" caption="Image" cellRender={this.customImage} ></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'} width={240}></Column>
                    </DataGrid>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <ModalConfirm
              isOpen={this.state.isOpenConfirm}
              title={'Confirmation Delete'}
              message={<><span> This action can not undo. </span> <br></br> <span>Are you sure you want to perform this action?</span></>}
              textButton={'Delete'}
              colorButton={"danger"}
              onCloseModal={() => {
                this.onClose();
              }}
              onActionConfirm={async () => {
                await this.onActionConfirm()
              }}
            />
            {
              this.state.currentExpertFeatured && this.state.currentExpertFeatured.id ?
                <Modal
                  isOpen={this.state.isDetail}
                  role="dialog"
                  autoFocus={true}
                  size="lg"
                  centered
                  data-toggle="modal"
                  toggle={() => {
                    this.onClose()
                  }}
                >
                  <div>
                    <ModalHeader
                      className="border-bottom-0"
                      toggle={() => {
                        this.onClose()
                      }}
                    >
                      { this.state.currentExpertFeatured.media_type == 'video' ? 'Video ' : 'Image '} Banner Featured
                    </ModalHeader>
                  </div>
                  <div className="modal-body pt-0">
                    <div className="mb-2">
                      <Row className=" justify-content-center">
                        <Col xl="12">
                          <Row>
                            <Col xl="12" style={{ textAlign: 'left' }} className="mb-4">
                              <div
                                className="text-center"
                              >
                                {
                                  this.state.currentExpertFeatured.media_type == 'video' ?
                                    <video
                                      className="video-feature-detail"
                                      muted
                                      playsInline
                                      preload="metadata"
                                      controls
                                      style={{ backgroundColor: "#f2f5f9" }}
                                      src={this.state.currentExpertFeatured.media}
                                      {...getVideoOptions(this.state.currentExpertFeatured?.media)}
                                    ></video>
                                    :
                                    <img
                                      id={`image_expert_featured_${this.state.currentExpertFeatured.id}`}
                                      src={
                                        this.state.currentExpertFeatured.media ?
                                          checkImage(this.state.currentExpertFeatured.media, `image_expert_featured_${this.state.currentExpertFeatured.id}`, imageExpert)
                                          : imageExpert
                                      }
                                      className="image-expert-featured-detail"
                                    ></img>
                                }
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Modal>
              : ''
            }
            <ModalConfirm
              isOpen={this.state.open_modal_confirm}
              title={'Confirmation Change'}
              message={'Do you want to save the previous changes?'}
              textButton={'Submit'}
              colorButton={'primary'}
              showCancel={true}
              textCancel={'Cancel'}
              onCloseModal={() => {
                this.onCloseModalConfirm();
              }}
              onActionConfirm={async () => {
                await this.onActionConfirmChangeOrder()
              }}
            />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Expert
